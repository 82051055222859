import request from "@/request";

export function userLogin(params:any){
    return request({
        url: '/user/auth/login',
        method: 'post',
        data: params
    })
}

export function getUserInfo(){
    return request({
        url: '/user/auth/account-info',
        method: 'get'
    })
}

export function userRegister(params:any){
    return request({
        url: '/user/auth/register',
        method: 'post',
        data: params
    })
}

export function userUpdatePassword(params:any){
    return request({
        url: '/user/auth/update-password',
        method: 'put',
        data: params
    })
}

export function userSendCode(params:any){
    return request({
        url: '/user/auth/send-code',
        method: 'post',
        data: params
    })
}

export function getUserDetail(id:number){
    return request({
        url: '/user/user/user/' + id,
        method: 'get'
    })
}

export function editUser(params:any){
    return request({
        url: '/user/user/user',
        method: 'put',
        data: params
    })
}