import router from "@/router";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useTodoStore = defineStore('todu', () => {
    const dialogFormVisible = ref(false)
    const dialogFormVisible1 = ref(false)
    const dialogFormVisible2 = ref(false)
    const dialogFormVisible3 = ref(false)
    const dialogFormVisible4 = ref(false)
    const dialogFormVisible5 = ref(false)
    const dialogFormVisible6 = ref(false)
    const dialogFormVisible7 = ref(false)
    const timer = ref(0)

    const showDialogFormVisible = (id: number) => {
        closeDialogFormVisible()
        switch (id) {
          case 0: dialogFormVisible.value = true; break;
          case 1: dialogFormVisible1.value = true; break;
          case 2: dialogFormVisible2.value = true; break;
          case 3: dialogFormVisible3.value = true; break;
          case 4: dialogFormVisible4.value = true; break;
          case 5: dialogFormVisible5.value = true; break;
          case 6: dialogFormVisible6.value = true; break;
          case 7: dialogFormVisible7.value = true; break;
          default: router.push({ name: 'register' });
        }
      }
      
      const closeDialogFormVisible = () => {
        dialogFormVisible.value = false
        dialogFormVisible1.value = false
        dialogFormVisible2.value = false
        dialogFormVisible3.value = false
        dialogFormVisible4.value = false
        dialogFormVisible5.value = false
        dialogFormVisible6.value = false
        dialogFormVisible7.value = false
      }
      

    return {
        dialogFormVisible,
        dialogFormVisible1,
        dialogFormVisible2,
        dialogFormVisible3,
        dialogFormVisible4,
        dialogFormVisible5,
        dialogFormVisible6,
        dialogFormVisible7,
        showDialogFormVisible,
        closeDialogFormVisible
    }
})